<template>
    <List
        model="companies"
        :can-delete="true"
        :can-edit="true"
        :can-view="false"
        default-sort="id"
        default-sort-order="desc"
    />
</template>
<script>
import List from "./list.vue"
export default {
    components: { List },
}
</script>
