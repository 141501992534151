<template>
    <div>
        <b-modal
            id="delete_modal"
            ref="delete_modal"
            title="Delete Confirmation"
            @ok="deleteConfirm"
        >
            <p class="my-4">
                Are you sure you want to delete?
            </p>
        </b-modal>
        <b-card v-if="checkPermission('list')">
            <div class="row mb-2">
                <div class="col col-md-4 col-lg-6">
                    <div
class="container"
style="display: flex;"
>
                        <import
                            v-if="
                                model === 'members' &&
                                    !trash &&
                                    checkPermission('import')
                            "
                            :model="model"
                            @imported="dataImported"
                        />
                        <b-button
                            v-if="checkPermission('export')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="btn-icon rounded-circle"
                            :title="exportButtonTitle"
                            style="margin: 5px;"
                            @click="exportData"
                        >
                            <feather-icon
                                icon="DownloadIcon"
                                svg-classes="h-10 w-10"
                            />
                        </b-button>
                        <b-button
                            v-if="
                                canDelete &&
                                    selected.length > 0 &&
                                    checkPermission('delete')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon rounded-circle"
                            title="Delete"
                            style="margin: 5px;"
                            @click="triggerDelete"
                        >
                            <feather-icon
                                icon="TrashIcon"
                                svg-classes="h-10 w-10"
                            />
                        </b-button>
                        <b-button
                            v-if="
                                canDelete &&
                                    selected.length > 0 &&
                                    trash &&
                                    checkPermission('delete')
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-info"
                            class="btn-icon rounded-circle"
                            title="Restore"
                            style="margin: 5px;"
                            @click="restore"
                        >
                            <feather-icon
                                icon="RotateCcwIcon"
                                svg-classes="h-10 w-10"
                            />
                        </b-button>
                    </div>
                </div>
                <div class="col col-md-4 col-lg-4">
                    <b-input-group>
                        <template v-slot:prepend>
                            <feather-icon
                                icon="SearchIcon"
                                svg-classes="h-10 w-10"
                            />
                        </template>
                        <b-form-input
                            v-model="search"
                            debounce="1000"
                            type="text"
                        />
                    </b-input-group>
                </div>
                <div class="col col-md-4 col-lg-2">
                    <div style="display: flex; justify-content: space-evenly;">
                        <b-button
                            v-if="checkPermission('create')"
                            variant="primary"
                            @click="addButtonClick"
                        >
                            <feather-icon icon="PlusIcon" />
                            <span class="ml-1 text-base">Add</span>
                        </b-button>
                        <b-button
                            v-if="canDelete && checkPermission('delete')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon rounded-circle"
                            :title="trash ? 'Trash' : 'List'"
                            style="margin: 2px;"
                            @click="toggleTrash"
                        >
                            <feather-icon
                                v-if="!trash"
                                icon="Trash2Icon"
                                svg-classes="h-10 w-10"
                            />
                            <feather-icon
                                v-else
                                icon="ListIcon"
                                svg-classes="h-10 w-10"
                            />
                        </b-button>
                    </div>
                </div>
            </div>
            <div
style="overflow-x: auto;"
class="mb-2"
>
                <b-table
                    id="selectableTable"
                    ref="selectableTable"
                    show-empty
                    empty-text="Sorry! No Data Found"
                    selectable
                    :select-mode="'multi'"
                    :items="items"
                    :fields="fields"
                    responsive="sm"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy="isLoading"
                    @row-selected="onRowSelected"
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle" />
                            <strong>&nbsp; Loading...</strong>
                        </div>
                    </template>
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <i class="feather icon-disc primary" />
                        </template>

                        <template v-else>
                            <i class="feather icon-circle" />
                        </template>
                    </template>

                    <template #cell(avatar)="data">
                        <b-avatar :src="data.value" />
                    </template>

                    <template
                        v-if="(canEdit || canView) && !trash"
                        #cell(action)="data"
                    >
                        <b-button
                            v-if="canEdit"
                            size="sm"
                            variant="primary"
                            @click="edit(data)"
                        >
                            <feather-icon
                                icon="EditIcon"
                                svg-classes="h-4 w-4"
                            />
                        </b-button>

                        <b-button
                            v-if="canView"
                            size="sm"
                            variant="primary"
                            @click="show(data)"
                        >
                            <feather-icon
                                icon="EyeIcon"
                                svg-classes="h-4 w-4"
                            />
                        </b-button>
                    </template>

                    <template #cell(status)="data">
                        <b-form-checkbox
                            v-if="
                                [
                                    'users',
                                    'bookings',
                                    'experiences',
                                    'events',
                                    'offers',
                                    'banners',
                                ].indexOf(module) >= 0
                            "
                            switch
                            size="sm"
                            :checked="data.item.status === 1"
                            @change="changeStatus(data.item.id)"
                        />
                        <p v-else>
                            {{
                                $helper.ucFirst(
                                    data.item.status.replaceAll('_', ' '),
                                )
                            }}
                        </p>
                    </template>
                    <template #cell(rsvp)="data">
                        <b-form-checkbox
                            switch
                            size="sm"
                            :checked="data.item.rsvp === 1"
                            @change="changeRsvp(data.item.id)"
                        />
                    </template>
                    <template #cell(is_public)="data">
                        <b-badge
                            v-if="data.item.is_public === 1"
                            variant="primary"
                        >
                            Yes
                        </b-badge>
                        <b-badge
v-else
variant="secondary"
>
                            No
                        </b-badge>
                    </template>

                    <template #cell(at_risk)="data">
                        <b-badge
                            v-if="data.item.at_risk === 0"
                            variant="success"
                        >
                            No
                        </b-badge>
                        <b-badge
v-else
variant="danger"
>
                            Yes
                        </b-badge>
                    </template>
                    <template #cell(on_premise)="data">
                        <b-badge
                            v-if="data.item.on_premise === 1"
                            variant="success"
                        >
                            Yes
                        </b-badge>
                        <b-badge
v-else
variant="warning"
>
                            No
                        </b-badge>
                    </template>
                    <template #cell(created_at)="data">
                        {{
                            $moment(data.item.created_at).format(
                                'YYYY-MM-DD h:mm a',
                            )
                        }}
                    </template>
                    <template #cell(updated_at)="data">
                        {{
                            $moment(data.item.updated_at).format(
                                'YYYY-MM-DD h:mm a',
                            )
                        }}
                    </template>
                    <template #cell(log_time)="data">
                        {{
                            $moment(data.item.created_at).format(
                                'MMM Do YYYY, h:mm a',
                            )
                        }}
                    </template>
                    <template>
                        <div
v-if="items.length === 0"
class="row"
>
                            <div class="col col-md-12">
                                <p>No Data Found</p>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
            <div v-show="items.length > 0">
                <div class="row">
                    <div class="col col-md-10">
                        <b-button
                            size="sm"
                            class="mr-1"
                            variant="primary"
                            @click="selectAllRows"
                        >
                            Select all
                        </b-button>
                        <b-button
                            size="sm"
                            class="mr-1"
                            variant="primary"
                            @click="clearSelected"
                        >
                            Clear selected
                        </b-button>
                    </div>
                    <div class="col col-md-2">
                        <b-pagination
                            v-if="total > 0"
                            v-model="currentPage"
                            :total-rows="total"
                            :per-page="perPage"
                            aria-controls="selectableTable"
                            align="right"
                        />
                    </div>
                </div>
            </div>
        </b-card>
        <b-card v-else>
            <b-container fluid>
                You don't have adequate permission to access this section.
                Please contact with your account admin for more details.
            </b-container>
        </b-card>
    </div>
</template>
<script>
import {
    BTable,
    BButton,
    BAvatar,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BPagination,
    BSpinner,
    BCard,
    BBadge,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import Import from '../common/import';
export default {
    name: 'List',
    components: {
        BTable,
        BButton,
        BAvatar,
        BPagination,
        BFormInput,
        BInputGroup,
        BSpinner,
        BFormCheckbox,
        BCard,
        BBadge,
        Import,
    },
    directives: {
        Ripple,
    },
    props: {
        model: {
            required: false,
            type: String,
            default: '',
        },
        conditions: {
            required: false,
            type: Object,
            default: null,
        },
        canDelete: {
            required: false,
            type: Boolean,
            default: false,
        },
        canEdit: {
            required: false,
            type: Boolean,
            default: true,
        },
        canView: {
            required: false,
            type: Boolean,
            default: false,
        },
        where: {
            type: Object,
            default: null,
        },
        defaultSort: {
            type: String,
            default: 'id',
        },
        defaultSortOrder: {
            type: String,
            default: 'asc',
        },
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: false,
            fields: [],
            items: [],
            selected: [],
            module: '',
            currentPage: 1,
            total: 0,
            perPage: 10,
            search: '',
            isLoading: false,
            trash: false,
        };
    },
    computed: {
        exportButtonTitle() {
            return this.selected.length > 0 ? 'Export Selected' : 'Export All';
        },
    },
    watch: {
        sortBy(val) {
            this.getData();
        },
        sortDesc(val) {
            this.getData();
        },
        search(val) {
            if (this.currentPage === 1) {
                this.getData();
            } else {
                this.currentPage = 1;
            }
        },
        currentPage() {
            this.getData();
        },
        where: {
            handler(val) {
                if (this.currentPage === 1) {
                    this.getData();
                } else {
                    this.currentPage = 1;
                }
            },
            deep: true,
        },
    },
    created() {
        // this.module = this.$route.name;
        this.module = this.lodash.isEmpty(this.model)
            ? this.$route.params.modules
            : this.model;
        this.sortBy = this.module === 'modules' ? 'display_order' : this.defaultSort;
        this.sortDesc = this.defaultSortOrder === 'desc';
        this.getData();
    },
    methods: {
        addButtonClick() {
            let url = `/account/companies/create`;
            if (this.module === 'members') {
                url = '/account/companies/create';
            }
            this.$router.push({
                path: url,
            });
        },
        checkPermission(action) {
            return this.$helper.checkUserPermission(
                action,
                this.module,
                this.$store.state.auth.user.permissions,
            );
        },
        onRowSelected(items) {
            this.selected = items;
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
        },
        getValue(item) {
            return item.status === 1;
        },
        async getData() {
            this.isLoading = true;
            const params = {
                show: this.perPage,
                sort: this.sortBy,
                sort_order: this.sortDesc ? 'desc' : 'asc',
                search: this.search,
                page: this.currentPage,
                trash: this.trash,
            };

            if (this.where) {
                params.where_clause = this.where;
            }
            const response = await this.$axios
                .get(`/${this.module}`, {
                    params,
                })
                .catch(err => {
                    console.error(err);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            const { fields } = response.data;
            const rows = response.data.results.results.data;
            this.total = response.data.results.results.total;
            this.lastPage = response.data.results.results.last_page;
            this.currentPage = response.data.results.results.current_page;
            const fieldArr = [];
            // eslint-disable-next-line no-restricted-syntax
            Object.entries(fields).map(field => {
                if (field[1].listing) {
                    fieldArr.push({
                        label: field[1].placeholder,
                        key: field[1].field_name,
                        sortable: field[1].sort,
                    });
                }
                return field;
            });
            if (this.canEdit || this.canView) {
                fieldArr.push({
                    label: 'Action',
                    key: 'action',
                });
            }

            this.fields = fieldArr;
            this.items = rows;
            this.isLoading = false;
        },
        edit(data) {
            this.$router.push({
                path: `/account/${this.module}/view/${data.item.id}`,
            });
        },
        show(data) {
            if (this.module === 'members') {
                this.$router.push({
                    path: `/account/contacts/show/${data.item.contact_id}`,
                });
            } else if (this.module === 'users') {
                this.$router.push({
                path: `/account/users/show/${data.item.user_id}`,
            });
            } else if (this.module === 'events') {
                this.$router.push({
                    path: `/account/experiences/show/${data.item.experience_id}`,
                    query: { experience_type: 'events' },
                });
            } else if (this.module === 'offers') {
                this.$router.push({
                    path: `/account/experiences/show/${data.item.experience_id}`,
                    query: { experience_type: 'offers' },
                });
            } else {
                this.$router.push({
                    path: `/account/${this.module}/show/${data.item.id}`,
                });
            }
            // this.$router.push({
            //     path: `/account/${this.module}/show/${data.item.id}`,
            // });
        },
        goToCreate() {
            this.$router.push({ path: `account/${this.module}/create` });
        },
        exportData() {
            let selectedIds = null;
            if (this.selected.length > 0) {
                selectedIds = this.selected.map(item => item.id);
            }
            const params = {
                ids: selectedIds,
                show: 100000000000000000000000,
                sort: this.sortBy,
                sort_order: this.sortDesc ? 'desc' : 'asc',
                search: this.search,
                page: this.currentPage,
                trash: this.trash,
            };
            if (this.where) {
                params.where_clause = this.where;
            }
            this.$helper.showLoading();
            this.$axios
                .get(`/${this.module}/export`, { responseType: 'blob', params })
                .then(resp => {
                    const url = window.URL.createObjectURL(
                        new Blob([resp.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    const filename = this.trash
                        ? `archived-${this.module}-${this.$moment().format(
                              'YYYY-MM-DD',
                          )}.csv`
                        : `${this.module}-${this.$moment().format(
                              'YYYY-MM-DD',
                          )}.csv`;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            this.$helper.hideLoading();
        },
        async changeStatus(id) {
            const response = await this.$axios
                .post(`/${this.module}/change`, {
                    id,
                })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Status Changed',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            });
        },
        async changeRsvp(id) {
            const response = await this.$axios
                .post(`/${this.module}/change`, {
                    field: 'rsvp',
                    id,
                })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'RSVP Status Changed',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            });
        },
        dataImported() {
            this.getData();
        },
        triggerDelete() {
            this.$refs.delete_modal.show();
        },
        toggleTrash() {
            this.trash = !this.trash;
            this.getData();
        },
        deleteConfirm() {
            let selectedIds = null;
            if (this.selected.length > 0) {
                selectedIds = this.selected.map(item => item.id);
            }
            let url = `/${this.module}/delete`;
            if (this.trash) {
                url = `/${this.module}/deleteParmanently`;
            }
            this.$helper.showLoading();
            this.$axios
                .post(url, { ids: selectedIds })
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data.status) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Record(s) Deleted',
                                icon: 'CheckIcon',
                                variant: 'primary',
                            },
                        });
                        this.getData();
                        this.$emit('needRefresh');
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: resp.data.message,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        restore() {
            let selectedIds = null;
            if (this.selected.length > 0) {
                selectedIds = this.selected.map(item => item.id);
            }
            this.$helper.showLoading();
            this.$axios
                .post(`/${this.module}/restore`, { ids: selectedIds })
                .then(resp => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Record(s) Restored',
                            icon: 'CheckIcon',
                            variant: 'primary',
                        },
                    });
                    this.getData();
                    this.$emit('needRefresh');
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss">
.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}
.table.b-table > tbody > .table-active,
[dir] .table.b-table > tbody > .table-active > th,
[dir] .table.b-table > tbody > .table-active > td {
    background-color: #04297f !important;
    color: #ffffff;
}
.input-group-prepend {
    margin-right: 10px;
    margin-top: 9px;
    padding: 1px;
}
</style>
